import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
    return (
      <>
        <div className="layout">
            <Header />
            <main className="content">
                <Outlet /> {/* This renders the nested routed components */}
            </main>
           
        </div>
      </>
    );
  };
  
  export default Layout;