
import httpClient from './httpClient';



const services =<T> () => {
  const http = httpClient();

  const getAll = (url: string) => {
    return http.get<Array<T>>(url);
  };

  const get = (url: string) => {
    return http.get<T>(url);
  };

  const post = (url: string, data: any) => {
    return http.post<T>(url, data);
  };

  const update = (url: string, data: any) => {
    return http.put<T>(url, data);
  };

  
  return {
    getAll,
    get,
    post,
    update,    
  };
};

export default services;
