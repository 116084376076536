import React from 'react';
import { Paper, Typography, Grid, Box, Avatar, Divider, Stack, Button, Tooltip } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import TokenIcon from '@mui/icons-material/Token';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const nftDetails = {
  id: '0x267ce48eeFa055988b1fB424c09646F2Fa836d38',
  creator: 'mich@gmail.com',
  owner: 'john@gmail.com',
  price: '5.2 ETH',
  mintDate: '2024-11-01',
  blockchain: 'Ethereum',
  description: 'This NFT represents a match-worn baseball jersey from the 2024 World Series.',
};

const NFTDetail = (data:any, productName:any) => {
  {console.log("jjsd---", productName)}
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {/* Paper for containing NFT details */}
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '16px',
          marginBottom: '20px',
        }}
      >
        {/* NFT ID */}
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <TokenIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              NFT ID
            </Typography>
            <Tooltip title={data.data.id}>
            <Typography variant="body1" color="text.secondary" sx={{
                                                                        marginTop: '10px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '200px', // Adjust the width to ensure the text can overflow
                                                                        display: 'block',
                                                                    }}>
              {data.data.id}
            </Typography>
            </Tooltip>
          </Grid>
        </Grid>

        {/* Creator and Owner */}
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item>
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <AccountCircleIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              Creator
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {data.data.creator}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item>
            <Avatar sx={{ bgcolor: 'success.main' }}>
              <AccountBalanceWalletIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              Current Owner
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {data.data.owner}
            </Typography>
          </Grid>
        </Grid>

        {/* Price */}
        {/* <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item>
            <Avatar sx={{ bgcolor: 'info.main' }}>
              <PriceCheckIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              Price
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {nftDetails.price}
            </Typography>
          </Grid>
        </Grid> */}

        {/* Mint Date and Blockchain */}
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item>
            <Avatar sx={{ bgcolor: 'warning.main' }}>
              <CalendarTodayIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              Mint Date
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {data.data.mintDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
          <Grid item>
            <Avatar sx={{ bgcolor: 'error.main' }}>
              <TokenIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              Blockchain
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {data.data.blockchain}
            </Typography>
          </Grid>
        </Grid>

        {/* NFT Description */}
        <Stack spacing={1} sx={{ marginTop: '16px' }}>
          <Typography variant="h6" fontWeight="bold">
            Description
          </Typography>
          <Divider />
          <Typography variant="body1" color="text.secondary">
            
            {"An NFT representing a digital reciept of the memorabilia piece"}
          </Typography>
        </Stack>

        {/* Button to view full details */}
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="contained" color="primary">
            View On Chain Details
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default NFTDetail;
