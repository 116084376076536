import { Link } from 'react-router-dom';

const Home = () => {

    return (
        <div>
            <Link to="/authscan">
            <p>Home</p>
            </Link>
        </div>
    )
}

export default Home;