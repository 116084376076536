import axios, { AxiosInstance } from 'axios';



const httpClient = (): AxiosInstance => {
  
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-type": "application/json",
    },
  });

  client.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Log the user out or handle the unauthorized status
        console.error('Unauthorized - logging out');
        // Your logout logic goes here
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default httpClient;
