import React, { useState } from 'react';
import { Box, Paper, Stack, Typography, Card, CardContent, BottomNavigation, BottomNavigationAction, Button, IconButton, AppBar, Toolbar} from "@mui/material";
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot} from "@mui/lab"


const MemorabiliaHistory = (data:any) => {
       

    return (

      <Box sx={{ flexGrow: 1 }}>
        {/* AppBar for the page title */}
        <AppBar position="static">
          <Toolbar>
            
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Memorabilia Ownership History
            </Typography>
          </Toolbar>
        </AppBar>
  
        {/* Timeline to display ownership history */}
        <Timeline sx={{ padding: "20px", marginBottom: "60px" }}>
          {data.data.map((item: any, index:number) => (
            <TimelineItem key={index} sx={{ "&::before": { flex: 0, padding: 0 } }}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  sx={{
                    padding: "10px 20px",
                    backgroundColor: "#f9f9f9",
                    boxShadow: 2,
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {item.event} 
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{paddingTop:'5px'}}>
                    {item.date}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
  
       
      </Box>
    );
  };

  export default MemorabiliaHistory;