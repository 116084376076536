const endpoints = {
    baseURL: process.env.REACT_APP_API_URL,

    product:{
        MemorabilaDetails: "api/product/ValidateMemorabilia",
        
    }
    

}


export const MEMORABILIADETAILS = `${endpoints.product.MemorabilaDetails}`;


