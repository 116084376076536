//import React, { useEffect, useState } from 'react';
import services from './services';
import {MEMORABILIADETAILS} from './config';



const { post } = services<any>();

export const validateMemorabilia = async (data:MemorabiliaValidationRequestDto) => {
       
    
    
        try{
           
            return await  post(MEMORABILIADETAILS, data);
            
        }
        catch (error) {
            console.log('An error occurred while fetching user details');
        }
        
        
} 


    
