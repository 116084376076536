import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Layout from "./components/Layout/Layout"; 
import Home from "./components/Home/Home";
import AuthScan from './components/AuthScan/AuthScan';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Replace with your desired primary color
      },
      secondary: {
        main: '#dc004e', // Replace with your desired secondary color
      },
    },
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,  // Wrap everything in Layout
      children: [
        { path: "/", element: <Home /> },
        {path:"/authscan", element: <AuthScan/>}
        
      ],
    },
  ]);
  return (
    <ThemeProvider theme={theme}>
    <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
