import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import MemorabiliaHistory from './MemorabiliaHistory';
import {validateMemorabilia} from '../../api/api';
import NFTDetail from './NFTDetail';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Paper, Stack, Typography, Card, CardContent, BottomNavigation, BottomNavigationAction, Button, IconButton, AppBar, Toolbar, CircularProgress} from "@mui/material";
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot} from "@mui/lab"
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryIcon from '@mui/icons-material/History';
import NftIcon from '@mui/icons-material/Style'; // Placeholder for NFT icon
import InfoIcon from '@mui/icons-material/Info';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RepeatIcon from "@mui/icons-material/Repeat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const AuthScan = () =>{

  const [searchParams] = useSearchParams();

  // Getting the 'id' parameter from the URL

  const id : string = searchParams.get('id') ?? '';
   // const text = "This is where the details about the memorabilia will be displayed. You can include any relevant information such as the history, significance, and condition of the item. Here is more detailed information that will be displayed when the user clicks \"View More.\"";

    const [value, setValue] = useState(0);
    const [showMore, setShowMore] = useState(false);
    
    const [loading, setLoading] = useState(true);   
    const [detail, setDetail] = useState<any>();
    const [text, setText]= useState('');
    const [showMoreView, setShowMoreView] = useState(text.length > 80);

     useEffect(() => {
      setLoading(true);
    const validateMem = async () => {
      try {
        var details = await validateMemorabilia({id:id});

        if(details)
        {
          setDetail(details.data);
          setText(details.data.details);
          setLoading(false);

          console.log("-----", details.data);
        }
      } catch (error) {
        console.log(error);
      }

      console.log("after validating tg user");
    };

    validateMem();
    
  }, []);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        
      };
      
      const AuthCard = (data:any) =>{

        
        const authDate = new Date().toLocaleDateString(); // Get current date in a readable format

        return (
            <div
           
            style={{
              padding: "16px",
              marginTop: "20px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row", // Set the flex direction to row
              maxWidth:'80%'
            }}
          >
            {/* Box containing text with the trademark */}
            <Box  sx={{ display: "flex", flexDirection: "column",alignItems: "center", gap: 2  }}>
              
              <Typography variant="overline" component="div">
                {data.data.productName}
                
              </Typography>
              
            </Box>
      
            {/* CheckCircleIcon on the right side */}
            <VerifiedIcon sx={{ fontSize: 20, color: "green" }} />
          </div>
        );
      }

      interface ScoreCardProps {
        fieldName: string;
        value: string | number;
      }
      
      const ScoreCard: React.FC<ScoreCardProps> = ({ fieldName, value }) => {
        return (
          <Card sx={{ margin: '10px', padding: '20px', textAlign: 'center', alignContent:'center', height:"120px" }}>
            <CardContent>
              <div  style={{textWrap:'nowrap', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                {value}
              </div>
              <div style={{textWrap:'nowrap', paddingTop:'20px'}} >
                {fieldName}
              </div>
            </CardContent>
          </Card>
        );
      };
      const authplaysettings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
    //console.log("djsjd----", detail.productName);
      
      return (
        <div style={{height:'100vh'}}>
        <div style={ {width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', maxHeight: '100%', overflowY:'auto'}}>
        
        { loading ? (
        // Show CircularProgress spinner when loading is true
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'  // Full viewport height to center it vertically
      }}>
          <CircularProgress />
      </div>
      ) : (
        value === 1 ? (<MemorabiliaHistory data={detail.history}/>) : (
           value === 2 ? (<NFTDetail data={detail.nft} productName={detail.productName}/>) : (<div style={ {width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', overflowY: 'auto', maxHeight: '100%'}}>
                <AuthCard data={detail}/>
                <Paper sx={{ maxWidth: "500px", margin: "0 auto", maxHeight:'20%', marginTop: "40px", width:'80%'  }}>
                
                <Slider {...settings}>
                
                {detail.images.map((image:string, index:number) => (
                  <div
                    key={index} 
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <img
                      style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                      src={image}
                      alt={`Image ${index + 1}`}
                    />
                  </div>
                ))}
                </Slider>
                </Paper>
                <div style={{ marginTop: "20px", padding: "16px", width: "80%", }}>
                    {/* <Typography variant="h6" component="div">
                        Memorabilia Details
                    </Typography> */}
                    <Typography variant="body1" component="div" sx={{
                                                                        marginTop: '10px',
                                                                        overflow: 'hidden',
                                                                        display: '-webkit-box',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        WebkitLineClamp: showMore ? 'none' : 2,
                                                                    }}>
                        {text} 
                    </Typography>
                    {showMoreView && (<div> {!showMore && (
                    <Button onClick={() => setShowMore(true)} sx={{ marginTop: '10px' }}>
                        View More
                    </Button>
                    )}
                    {showMore && (
                    <Button onClick={() => setShowMore(false)} sx={{ marginTop: '10px' }}>
                        View Less
                    </Button>
                    )}</div>)}
                           
                 </div>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', width: '100%', paddingTop:'20px' }}>
     
            
                {detail.traits.map((trait:any, index:number) => (
                  <div key={index} style={{ marginLeft:'10px' }}>
                    <ScoreCard fieldName={trait.traitName} value={trait.traitValue} />
                  </div>
                ))}    
       
      
                </div>
                </div>)
        ))}

         <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                    setValue(newValue);
                    
                    }}
                >
                    <BottomNavigationAction label="Details" icon={<InfoIcon />} />
                    <BottomNavigationAction label="History" icon={<HistoryIcon />} />
                    <BottomNavigationAction label="NFT" icon={<NftIcon />} />
                    
                    
                </BottomNavigation>
            </Paper>
           </div>
           
            </div>
      );
}

export default AuthScan;